<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

  </section>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'CompetencyModel',
  components: { BlueHeader },
  data: () => ({
    itemsHeader: [
      {
        name: 'Competencia',
        link: { name: 'modules.humanTalent.competencyModel.competencia' },
        rol: Role.TalentoHumano_ModeloCompetencias_Competencia,
      },
      {
        name: 'Habilidades',
        link: { name: 'modules.humanTalent.competencyModel.habilidades' },
        rol: Role.TalentoHumano_ModeloCompetencias_Habilidades,
      },
    ]
  }),
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 96%;
}
</style>